
/*footer*/
.footer__wrap{
  border-top: 1px solid rgba(255, 255, 255, .15);
  padding: 20px 0;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-logo {
  margin-right: 20px;
}
.footer__contacts{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 640px;
}
.footer-contact__title{
  margin-bottom: 6px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: rgba(255, 255, 255, .4);
}
.footer-contact__links{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer-contact__links a{
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  margin-right: 24px;
}
.footer-contact__links a:last-child{margin-right: 0;}
.footer-contact__links a:hover{opacity: .75;}
@media only screen and (max-width : 1023px){
  .footer__wrap{
    padding: 32px 0;
    display: block;
  }
  .footer__contacts{
    justify-content: flex-start;
    margin-top: 24px;
  }
  .footer-contact:not(:last-child){margin-right: 64px;}
}
@media only screen and (max-width : 580px){
  .footer__contacts{display: block;}
  .footer-contact:not(:last-child){margin: 0 0 24px;}
  .footer-contact__links a:not(:last-child){margin-right: 16px;}
}

.footer__no-container {
  position: relative;
  width: 100%;
  padding: 0 1rem;
  margin-top: auto;
}

@media only screen and (min-width : 1024px){
  .footer__no-container {
    width: calc(100% - 22rem);
    padding: 0 2rem 0 0;
    margin-left: auto;
  }
}
