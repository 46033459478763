.header {
  @apply flex items-center rounded-3xl w-full bg-cover pt-8 pb-5 flex-wrap;
}

.header_text {
  @apply flex flex-col items-center space-y-2 text-center;
}

.buttons {
  @apply flex items-center justify-center space-x-4 mt-8;
}

.title_bar_button {
  @apply flex space-x-1 mt-2 xs:mt-0;
}

.table-container {
  overflow-x: auto;
}
.table_row {
  @apply flex justify-between text-white flex-wrap xs:flex-nowrap;
  border: 1px solid rgba(var(--white), 0.15);
}
.table_row_placeholder {
  @apply flex justify-center text-white;
  border: 1px solid rgba(var(--white), 0.15);
}

.table_row_placeholder_noborder {
  border: 0;
}

.table_item {
  @apply w-full flex flex-col items-start justify-center py-2 px-4;
  border-right: 1px solid rgba(var(--white), 0.15);
}

.external {
  margin-left: 10px;
}

.external {
  fill: rgba(var(--green-100), 1);
}

.external g {
  opacity: 1;
}

.external path {
  fill: rgba(var(--green-100), 1);
  stroke: rgba(var(--green-100), 1);
}

.symbolWrap {
  position: relative;
  cursor: pointer;
}


.symbolWrap:hover span:last-child {
  opacity: 1;
  visibility: visible;
}

.symbolFull {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  background-color: black;
  visibility: hidden;
  transition: visibility .15s ease, opacity .15s ease;
}