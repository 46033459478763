.dropzone {
  @apply text-secondary-600 relative;
  background-color: rgba(var(--white), .2);
  border: 1px solid var(--green-100);
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color .15s ease, box-shadow .15s ease;
}

.upload {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  transition: fill .15s ease;
}

.dropzone:hover {
  background-color: rgba(var(--white), .3);
  box-shadow: 0 0 6px 4px rgba(var(--green-100), 0.6)
}

.dropzone:hover .upload {
  fill: rgb(var(--green-100));
}

.highlight {
  background-color: rgba(var(--green-100), .5);
  border: 1px solid var(--green-100);
}

.notify {
  @apply absolute text-sm bg-green-100 p-2 px-3 rounded top-2 right-2;
  transition: opacity .15s ease;
}

.showNotify {
  opacity: 1;
}

.hideNotify {
  opacity: 0;
}