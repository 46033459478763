.container {
  @apply flex flex-col items-center text-center max-w-sm;
  @apply my-auto mx-auto py-16 px-4;
  @apply text-secondary-500;
}

.image {
  @apply w-full;
}

.title {
  @apply text-2xl my-3 text-secondary-600 font-bold;
}

.subtitle {
  @apply mb-6 max-w-xs;
}
