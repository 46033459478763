html, body{
  width: 100%;
}
.wrapper{
  width: 100%;
  color: #fff;
  background: #090015;
  min-width: 310px; 
  position: relative;
  opacity: 1;
  -moz-font-feature-settings: 'kern';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

/*buttons*/
.btn{
  display: block;
  padding: 0;
  outline: 0!important;
  outline-color: transparent!important;
  outline-width: 0!important;
  outline-style: none!important;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  border: none;
  background: none;
  box-shadow: none;
  transition: .25s ease;  
  cursor: pointer;
  border-radius: 0;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.btn::-moz-focus-inner {border: 0;}
.btn:disabled {
  cursor: default;
  opacity: 1;
  user-select: none;	
}
.btn--primery{
  width: fit-content;
  background: #11A97D;
  border-radius: 8px;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  padding: 16px 20px;
  color: #fff;
  box-shadow: 0 0 0 0 rgba(17, 169, 125, 0);
  transition: box-shadow .2s ease, background-color .2s ease;
}
.btn--primery:hover{
  box-shadow: 0 0 0 6px rgba(17, 169, 125, .4);
}
.btn--primery:active{
  background-color: rgba(17, 169, 125, .8);
  box-shadow: 0 0 0 6px rgba(17, 169, 125, .3);
}
.btn--primery.disabled,
.btn--primery:disabled{
  background-color: rgba(17, 169, 125, .5);
  box-shadow: 0 0 0 0 rgba(17, 169, 125, 0);
}
.btn--secondary{
  width: fit-content;
  background: transparent;
  border-radius: 8px;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  padding: 16px 20px;
  color: #11A97D;
  transition: color .2s ease, background-color .2s ease;
}
.btn--secondary:hover{
  background-color: rgba(17, 169, 125, .15);
}
.btn--secondary:active{
  background-color: rgba(17, 169, 125, .15);
  color: rgba(17, 169, 125, .8);;
}
.btn--secondary.disabled,
.btn--secondary:disabled{
  background-color: transparent;
  color: rgba(17, 169, 125, .5);
}
.btn--border{
  width: fit-content;
  background: rgba(17, 169, 125, .15);
  border-radius: 8px;  
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  padding: 7px 12px;
  color: #11A97D;
  box-shadow: 0 0 0 1px rgba(17, 169, 125, .15);
  transition: box-shadow .2s ease, color .2s ease;
}
.btn--border:hover{
  box-shadow: 0 0 0 6px rgba(17, 169, 125, .4);
}
.btn--border:active{
  background-color: rgba(17, 169, 125, .6);
  box-shadow: 0 0 0 1px rgba(17, 169, 125, .15);
}
.btn--border.disabled,
.btn--border:disabled{
  background-color: rgba(17, 169, 125, .5);
  box-shadow: 0 0 0 1px rgba(17, 169, 125, .15);
}
.bar{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}
.bar > *:not(:last-child){margin-right: 16px;}

/*grid*/
.wrapper{
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}
.container{
  width: 100%;
  max-width: 1208px;
  padding: 0 24px;
  margin: 0 auto;
}
@media only screen and (max-width : 1023px){
  .container{
    padding: 0 16px;
    max-width: 640px;
  }
}

.sec-title{
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 48px;
}
@media only screen and (max-width : 580px){
  .sec-title{
    font-size: 28px;
    line-height: 36px;
  }
}



















