.main {
  @apply flex flex-col;
}

.picker_container {
  @apply flex items-center w-full;
}

.error {
  @apply px-1;
  @apply text-danger-600 text-sm;
}
