.option {
  @apply flex items-center cursor-pointer px-2 py-2;
}

.option span {
  transition: transform .1s ease;
}

.option:hover span {
  transform: translateX(3px);
}

.option_icon {
  @apply w-6 h-6 mr-2;
}

.option_label {
  @apply text-white text-ellipsis overflow-hidden max-w-[150px];
}

.value {
  @apply flex items-center gap-2;
}

.value_icon {
  @apply w-6 h-6;
}

.value_label {
  @apply text-white;
}

.single_value_label {
  @apply text-white text-ellipsis overflow-hidden max-w-[90px];
}
