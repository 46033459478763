@import 'react-toastify/dist/ReactToastify.css';

body {
  --toastify-color-light: rgb(var(--color-tertiary-900));
  --toastify-color-success: rgb(var(--color-success-900));
  --toastify-color-error: rgb(var(--color-danger-900));

  --toastify-toast-width: 420px;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Poppins;
  --toastify-z-index: 9999;

  --toastify-text-color-light: rgb(var(--color-secondary-600));

  --toastify-spinner-color: rgb(var(--color-secondary-600));
  --toastify-spinner-color-empty-area: rgb(var(--color-secondary-300));

  --toastify-color-progress-light: rgb(var(--color-secondary-400));
}
