.header {
  @apply rounded-3xl w-full bg-cover mt-8;
}

.header_text {
  @apply flex space-y-2 text-center mb-8;
}

.buttons {
  @apply flex items-center justify-center space-x-4 mt-8;
}

.spinner {
  @apply text-secondary-400 text-4xl mb-20 w-12 h-12;
}