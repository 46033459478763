.input {
  @apply absolute hidden;
}

.checkbox {
  @apply text-opacity-0 text-white bg-secondary-200 rounded-[4px];
  @apply flex justify-center items-center;
  @apply w-5 h-5 select-none cursor-pointer;
}

.disabled {
  @apply opacity-50 pointer-events-none;
}

.input:checked ~ .checkbox {
  @apply text-opacity-100 bg-green-100;
}
