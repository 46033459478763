.header {
  @apply flex items-center rounded-3xl w-full bg-cover pt-12 pb-5 flex-wrap;
}

.header_text {
  @apply flex flex-col items-center space-y-2 text-center;
}

.buttons {
  @apply flex items-center justify-center space-x-4 mt-8;
}

.title_bar_button {
  @apply flex space-x-1 mt-2 xs:mt-0;
}

.table-container {
  overflow-x: auto;
}
.separator {
  @apply h-[1px] w-full bg-white/10 mb-8;
}

.spinner {
  @apply text-secondary-400 text-4xl ml-4;
}