.header {
  @apply flex items-center rounded-3xl w-full bg-cover pt-12 pb-5 flex-wrap;
}

.header_text {
  @apply flex flex-col items-center space-y-2 text-center;
}

.buttons {
  @apply flex items-center justify-center space-x-4 mt-8;
}

.title_bar_button {
  @apply flex space-x-1;
}

.spinner {
  @apply text-secondary-400 text-4xl max-w-[240px];
}
