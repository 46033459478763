.container {
  @apply flex flex-col items-center justify-center space-y-10;
  @apply w-full px-6 pt-16 sm:pt-12;
}

.account {
  box-shadow: var(--sidebar-account-box-shadow);

  @apply bg-tertiary-900;
  @apply flex items-center space-x-4 w-full rounded-2xl p-4;
}

.account_logo {
  background: url('../../../assets/images/user-placeholder.png');
  @apply bg-cover rounded-full w-16 h-16;
}

.menu {
  @apply flex flex-col gap-6 w-full px-4;
}

.link {
  @apply flex items-center space-x-2 text-secondary-400;
}

.link_icon {
  @apply text-2xl;
}

.logout {
  @apply flex items-center space-x-2 text-secondary-400;
}
