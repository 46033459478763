.table-bordered {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid rgba(var(--white), 0.3);
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 40px;
  overflow-x: auto;
}

.table-bordered--overflow {
  min-width: 100%;
  overflow: auto;
}

.table-bordered tr {
  cursor: pointer;
}

.table-bordered thead tr {
  cursor: auto;
}
.table-bordered tr:hover {
  background-color: rgba(var(--white), 0.2);
  transition: background-color 0.1s ease-in-out;
}
.table-bordered thead tr:hover {
  background-color: transparent;
}
.table-bordered--nohover tr {
  cursor: auto;
}
.table-bordered--nohover tr:hover {
  background-color: transparent;
}
.table-bordered td, .table-bordered th {
  border-left: 1px solid rgba(var(--white), 0.3);
  border-top: 1px solid rgba(var(--white), 0.3);
  padding: 10px;
  text-align: left;
}
.table-bordered td {
  color: rgba(var(--white), 1);
  font-size: 14px;
}
.table-bordered th {
  background-color: rgba(var(--white), 0.05);
  color: rgba(var(--white), 0.5);
  border-top: none;
  font-size: 14px;
  font-weight: 600;
}
.table-bordered td:first-child, .table-bordered th:first-child {
  border-left: none;
}
.table-bordered th:first-child {
  border-radius: 8px 0 0 0;
}
.table-bordered th:last-child {
  border-radius: 0 8px 0 0;
}
.table-bordered th:only-child{
  border-radius: 8px 8px 0 0;
}
.table-bordered tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}
.table-bordered tr:last-child td:last-child {
  border-radius: 0 0 8px 0;
}