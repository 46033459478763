.react-datepicker_disabled {
  @apply text-secondary-400;
}

.react-datepicker__input-container-main {
  @apply bg-primary-900 text-secondary-600 rounded-xl overflow-hidden;
  @apply flex items-center gap-2 px-4 py-2;
  @apply border border-white/15;
  transition: border .15s ease, box-shadow .15s ease;
}

.react-datepicker__input-container-main:focus-within {
  @apply border-green-100;
  box-shadow: 4px 4px 0px rgba(var(--green-100), 0.4);
}

.react-datepicker {
  @apply bg-secondary-100 rounded-xl;
  @apply border border-secondary-200;
  @apply px-2 py-2;
  @apply shadow-primary;
}

.react-datepicker-wrapper {
  @apply flex;
}

.react-datepicker-ignore-onclickoutside {
  @apply bg-secondary-100;
}

.react-datepicker__input-container-single {
  @apply bg-secondary-100 overflow-hidden outline-none min-w-[44];
}

.react-datepicker-input {
  @apply bg-transparent w-full outline-none;
}

.react-datepicker__input-container {
  @apply min-w-full;
}

.react-datepicker__close-icon::after {
  @apply bg-secondary-100 h-7 w-7 text-xl text-primary-900 leading-none;
}

.react-datepicker__header {
  @apply bg-secondary-100 pt-0 pb-2.5;
}

.react-datepicker__navigation {
  @apply mt-2;
}

.react-datepicker__month-container {
  @apply float-left;
}

.react-datepicker__current-month {
  @apply text-lg pb-2;
}

.react-datepicker__day {
  @apply text-secondary-600;
}

.react-datepicker__day--in-range {
  @apply bg-secondary-400 text-tertiary-900;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-transparent;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range
  ) {
  @apply bg-secondary-300;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
  @apply bg-secondary-400 text-secondary-100;
}

.react-datepicker__day:hover {
  @apply bg-secondary-300;
}

.react-datepicker__day--disabled {
  @apply opacity-50;
}

.react-datepicker__day--disabled:hover {
  @apply bg-transparent text-secondary-400;
}

.react-datepicker__day--outside-month {
  @apply invisible;
}

.react-datepicker-popper[data-placement^='bottom'] {
  @apply pt-5;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  @apply border-none;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before {
  @apply border-none;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  @apply border-t-secondary-100;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::after {
  @apply border-t-secondary-100;
}

.react-datepicker__time-container .react-datepicker__time {
  @apply bg-transparent;
}

.react-datepicker__time-container {
  @apply border-none max-w-[60px];
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  @apply w-full;
}

.react-datepicker-time__header {
  @apply text-xs justify-center flex items-end h-[3.78rem] text-secondary-600;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  @apply text-secondary-600;
}

.react-datepicker__day-name {
  @apply text-secondary-600;
}

.react-datepicker__time-list {
  @apply border-l border-secondary-300 pt-1.5;
}

li.react-datepicker__time-list-item {
  @apply rounded-lg text-secondary-600;
}

li.react-datepicker__time-list-item--selected {
  @apply !bg-primary-900;
}

.react-datepicker__header--has-time-select .react-datepicker__current-month {
  @apply text-end pr-4;
}

.react-datepicker__header--has-time-select,
.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  @apply right-0;
}

.react-datepicker__year-read-view--down-arrow {
  @apply top-0.5;
}

.react-datepicker__year-dropdown {
  @apply bg-secondary-100 rounded-xl h-24;
  @apply border border-secondary-300;
  @apply shadow-primary;
}

.react-datepicker__year-option {
  @apply rounded-lg;
}

.react-datepicker__year-option--selected_year {
  @apply !bg-primary-900 text-tertiary-900;
}

.react-datepicker__year-option--selected {
  @apply hidden;
}

.react-datepicker__navigation--years-upcoming,
.react-datepicker__navigation--years-previous {
  @apply hidden;
}

.react-datepicker_select_container {
  @apply bg-secondary-100 text-secondary-400 rounded-xl overflow-hidden;
  @apply flex items-center gap-2 px-4 py-2;
  @apply border border-secondary-100;
}

.react-datepicker_select_input {
  @apply w-full text-primary-900 bg-secondary-100/0 outline-none;
}

.react-datepicker-input::placeholder {
  @apply text-white/40;
}

.react-datepicker__calendar {
  @apply text-secondary-400;
  @apply shrink-0;
}

.react-datepicker ::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.react-datepicker ::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
}

.react-datepicker__time-list {
  scrollbar-width: thin;
}
