@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Poppins:wght@500;600;700&display=swap');
@import 'react-popper-tooltip/dist/styles.css';
@import 'react-datepicker/dist/react-datepicker.css';
@import './notifications.css';
@import './datepicker.css';
@import './animations.css';
@import './tooltip.css';
@import './tables.css';
@import './theme-black.css';

@layer base {
  body {
    font-family: 'Open sans', sans-serif;

    @apply bg-primary-900;
    @apply w-screen overflow-hidden;
    @apply text-primary-900;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.root {
  position: relative;
}

.page__wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.breadcrumbs__link {
  color: rgba(var(--white), 1);
  margin-right: 1rem;
  transition: color .15s ease;
}

.breadcrumbs__link--active {
  color: rgba(var(--white), .5);
  pointer-events: none;
}

.breadcrumbs__link:hover {
  color: rgba(var(--green-100), 1);
  text-decoration: underline;
}

.breadcrumbs___icon {
  color: rgba(var(--white), .5);
  margin-right: 1rem;
}

.account-lg__wrap {
  @apply hidden lg:block;
}

.page_separator {
  @apply h-[1px] w-full bg-white/10 mb-8;
}

.recharts-wrap {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(var(--white), .15);
  padding: 10px;
}

.rc-notification {
  min-width: 320px;
}

.rc-notification-notice {
  margin-right: 12px!important;
  backdrop-filter: blur(10px);
}

.app {
  @apply w-full lg:w-screen min-h-screen max-h-screen overflow-x-hidden overflow-y-auto;
  @apply flex flex-col
}

.copy {
  @apply ml-2 cursor-pointer hover:bg-white;
  border-radius: 4px;
  padding: 2px;
  transition: background-color .2s ease;
}

.copy path {
  transition: stroke .2s ease;
}

.copy:hover path {
  stroke: rgb(var(--green-100));
}