.header {
  @apply rounded-3xl w-full bg-cover mt-8;
}

.header_text {
  @apply flex space-y-2 text-center mb-8;
}

.buttons {
  @apply flex items-center justify-center space-x-4 mt-8;
}

.spinner {
  @apply text-secondary-400 text-4xl mb-20 w-12 h-12;
}

.actionsUsers {
  display: flex;
  position: absolute;
  right: -90px;
  top: 70%;
  transform: translateY(-50%);
}

.actionsUsers span {
  @apply px-2 py-2 text-danger-600 cursor-pointer rounded transition-colors hover:bg-danger-600 hover:text-white;
}