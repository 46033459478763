
/*header*/
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}
.header-nav ul{
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-nav ul li{margin-left: 40px;}
.header-nav ul li a{
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  transition: opacity .2s;
  opacity: .75;
}
.header-nav ul li a:hover{opacity: 1;}
.header__app{display: none;}
.touch .header-nav{display: none;}
.touch .header__app{display: block;}
@media only screen and (max-width : 1023px){
  .header{padding: 12px 16px;}
}

/*hero*/
.hero{
  background: url(../img/hero-bg-min.png) center bottom no-repeat;
  background-size: 1360px auto;
}
.hero__wrap{
  height: 628px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 818px;
  margin: 0 auto;
  text-align: center;
}
.hero__title{
  margin-bottom: 28px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
}
.hero__txt{
  margin-bottom: 28px;
  font-size: 19px;
  line-height: 32px;
  opacity: .9;
}
@media only screen and (max-width : 1023px){
  .hero{background-size: 100% auto;}
}
@media only screen and (max-width : 580px){
  .hero__wrap{
    height: auto;
    padding: 32px 0 52px;
  }
  .hero__title{
    font-size: 40px;
    line-height: 48px;
  }
  .hero .bar{
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  .hero .bar .btn:first-child{
    width: 100%;
    margin: 0 0 16px;
  }
}

/*main-sec*/
.main-sec{padding: 120px 0 80px;}
.main-sec .sec-title{max-width: 748px;}
.main-sec__wrap{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.main-sec__pic,
.main-sec__content{width: calc(50% - 20px);}
.main-sec__pic{position: relative;}
.main-sec__pic img{
  position: sticky;
  top: 0;
}
.main-sec__pic img:last-child{display: none;}
.main-sec-item{
  position: relative;
  padding: 24px 0 24px 72px;
}
.main-sec-item::before,
.main-sec-item::after{
  content: '';
  display: block;
  position: absolute;
}
.main-sec-item::before{
  width: 2px;
  height: 100%;
  top: 0;
  left: 32px;
  background-color: #11A97D;
}
.main-sec-item::after{
  top: 35px;
  width: 14px;
  height: 14px;
  left: 26px;
  border-radius: 50%;
  border: 2px solid #11A97D;
  box-shadow: 0 0 44px 8px #11A97D, 0 0 0 11px #090015;
  background-color: #090015;
}
.main-sec-item:last-child::before{height: 24px;}
.main-sec-item__header{margin-bottom: 15px;}
.main-sec-item__link{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #11A97D;
}
.main-sec-item__link img{
  margin-left: 16px;
  opacity: .5;
  transition: opacity .1s, transform .1s;
}
.main-sec-item__link:hover{text-decoration: underline;}
.main-sec-item__link:hover img{
  opacity: 1;
  transform: translateX(8px);
}
.main-sec-item ul li{
  margin-top: 8px;
  opacity: .9;
  font-size: 17px;
  line-height: 28px;
  position: relative;
  padding-left: 26px;
}
.main-sec-item ul li::before{
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  opacity: .9;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  left: 11px;
  top: 13px;
}
@media only screen and (max-width : 1023px){
  .main-sec{padding: 64px 0;}
  .main-sec__content,
  .main-sec .sec-title{
    position: relative;
    z-index: 3;
  }
  .main-sec__wrap{display: block;}
  .main-sec__pic,
  .main-sec__content{width: 100%}
  .main-sec__pic{
    margin-bottom: 48px;
    position: relative;
    padding-top: 56%;
  }
  .main-sec__pic img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .main-sec__pic img:last-child{display: block;}
  .main-sec__pic img:first-child{display: none;}
}
@media only screen and (max-width : 580px){
  .main-sec{padding: 48px 0;}
  .main-sec-item{padding-left: 56px;}
  .main-sec-item::before{left: 22px;}
  .main-sec-item::after{left: 16px;}
}

/*advantages*/
.advantages{
  padding: 120px 0;
  background: url(../img/advantages-bg-min.png) center bottom no-repeat;
  background-size: 1360px auto;
}
.advantages__row{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;  
}
.advantage__icn{margin-bottom: 16px;}
.advantage__title{
  margin-bottom: 12px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}
.advantage__txt{
  font-size: 17px;
  line-height: 28px;
  opacity: .9;
}
@media only screen and (max-width : 1023px){
  .advantages{padding: 64px 0;}
  .advantages__row{
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
  }
}
@media only screen and (max-width : 580px){
  .advantages{padding: 64px 0;}
  .advantages__row{
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
  }
}

/*action*/
.action{
  position: relative;
}
.action__wrap{
  padding: 200px 0 212px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 760px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  
}
@media only screen and (max-width : 1023px){
  .action::before{background-size: 100% auto;}
  .action__wrap{padding: 121px 0;}
}
@media only screen and (max-width : 580px){
  .action__wrap{padding: 96px 0;}
}













