.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body {
  display: flex;
  padding-right: 48px;
  box-sizing: border-box;
}

.link {
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #7075e9;
  cursor: pointer;
}