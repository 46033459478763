.header {
  @apply absolute top-0 left-0 w-full flex items-center pr-8;
  @apply z-20 lg:w-3/12 lg:max-w-[330px] lg:pr-0 lg:px-0;
}

.header_container {
  @apply w-full flex items-center lg:items-start justify-between pl-4 py-8 bg-primary-900 lg:z-0 lg:hidden;
}

.logo {
  @apply cursor-pointer z-10 lg:hidden;
}
