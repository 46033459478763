.account {
  @apply flex justify-between items-center z-20;
  @apply lg:absolute lg:right-8 lg:top-6;
  backdrop-filter: blur(10px);
  padding: 5px 7px;
  border-radius: 8px;
}

.hideAccount {
  @apply hidden;
}

.account_logo {
  background: url("../../../assets/images/user-placeholder.png");
  @apply bg-cover rounded-full w-10 h-10 mt-6;
}

.btn_exit path:first-child {
  fill: rgb(var(--green-100));
}

.btn_exit path:last-child {
  stroke: rgb(var(--green-100));
}
