.button {
  @apply flex items-center justify-center;
  @apply px-3 py-3 rounded-[12px] text-sm font-bold transition-all;
  backdrop-filter: blur(10px);
}

.button:hover {
  opacity: .9;
  box-shadow: 0 0 6px 4px rgba(var(--green-100), 0.4);
}

.button:active {
  transform: scale(0.96);
}

.button:disabled {
  pointer-events: none;
  @apply opacity-40;
}

.button:disabled.error {
  @apply bg-danger-600;
}

.spinner_btn {
  @apply text-xl mr-2;
}

.primary {
  @apply text-green-100 bg-green-100/[15%];
  border: 1px solid rgba(var(--green-100), 0.15);
}

.primary_inverse {
  border: 1px solid var(--color-button-primary-bg);
  color: var(--color-button-primary-bg);
}

.secondary {
  @apply bg-green-100 text-white;
}

.secondary_inverse {
  @apply text-green-100;
  border: 0;
}

.tertiary {
  background: var(--color-button-tertiary-bg);
  color: var(--color-button-tertiary);
}

.tertiary_inverse {
  border: 1px solid var(--color-button-tertiary-bg);
  color: var(--color-button-tertiary-bg);
}