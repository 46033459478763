.container {
  @apply bg-primary-900 text-secondary-400 rounded-xl overflow-hidden;
  @apply flex items-center gap-2 px-4 py-2.5;
  @apply border border-white/15;
  transition: border .15s ease, box-shadow .15s ease;
}

.input {
  @apply w-full text-secondary-600 bg-primary-900 outline-none;
}

.disabled {
  @apply bg-white/5;
}

.input:disabled {
  @apply bg-transparent;
}

.input::placeholder {
  @apply text-white/40;
}

.container:focus-within {
  @apply border-green-100;
  box-shadow: 4px 4px 0px rgba(var(--green-100), 0.4);
}

.input::placeholder {
  @apply opacity-100;
}

.error {
  @apply px-1;
  @apply text-danger-600 text-sm;
}
