.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 3002;
}

.modalHeader {
  position: sticky;
  top: 0;
  z-index: 10;
}

.full .modalHeader {
  width: 100%;
}

.open {
  display: flex;
}

.overlay {
  position: absolute;
  inset: 0;
  z-index: 10;
  background-color: #121212;
  opacity: 0.8;
  cursor: pointer;
}

.content {
  position: relative;
  background-color: rgba(var(--color-secondary-100), 0.3);
  backdrop-filter: blur(30px);
  border-radius: 16px;
  padding: 16px 16px;
  z-index: 15;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.content.full {
  border-radius: 0;
}

.contentScroll {
  max-height: 80dvh;
  overflow-y: auto;
}

.contentScroll.full & {
  max-height: 100dvh;
  overflow-y: auto;
}

.logo_cnt {
  position: fixed;
  left: 1rem;
  top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close_cnt {
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 100;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.close_cnt svg {
  transition: fill 0.15s ease;
}

.close_cnt:hover svg {
  fill: rgb(var(--white));
}

.close_cnt_fixed {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 100;
}
