.header {
  @apply flex items-center rounded-3xl w-full bg-cover pt-12 pb-5 flex;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.cardItem {
  @apply w-full md:w-1/2;
  display: flex;
  padding: 15px;
  cursor: pointer;
}

.cardItem:first-child {
  border-radius: 8px 0 0 0;
}

.cardItem:nth-child(2) {
  border-radius: 0 8px 0 0;
}

.cardItem:last-child {
  border-radius: 0 0 8px 0;
}

.cardItem:nth-last-child(2) {
  border-radius: 0 0 0 8px;
}

.cardCheckboxActive .cardsText span:first-child {
  @apply text-green-100;
}

.cardCheckboxActive .cardsText span:last-child {
  @apply text-white/80;
}


.cardsText {
  display: flex;
  flex-direction: column;
}

.cardsText span:first-child {
  @apply text-white text-base transition-colors;
}

.cardsText span:last-child {
  @apply text-white/50 text-sm mt-2;
}

.cardItem:hover .cardsText span:first-child {
  @apply text-green-100;
}

.cardItem:hover .cardsText span:last-child {
  @apply text-white/80;
}

.spinner {
  @apply text-secondary-400 text-4xl mt-6 mb-6 w-12 h-12;
}