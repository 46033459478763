.navbar {
  @apply absolute w-full top-0 left-0 h-screen bg-primary-900 max-w-[320px] transition-all;
  @apply px-4 pt-28 z-10;
  @apply lg:z-10 lg:pt-8 lg:relative lg:w-full lg:opacity-100;
  @apply -translate-x-32 lg:translate-x-0;
}

.showNavbar {
  @apply opacity-100 translate-x-0 visible;
}

.hideNavbar {
  @apply opacity-0 -translate-x-32 invisible;
}

.hideNavbar a {
  @apply hidden;
}

.brand_container {
  @apply flex flex-col mb-12 relative z-10 lg:z-0;
}

.logo {
  @apply mb-8 cursor-pointer z-10 hidden lg:block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  padding-right: 100px;
}

.page_link {
  @apply relative flex items-center text-white/50 font-semibold text-sm my-2 py-2 pr-2;
  transition: color .15s ease;
}

.page_link::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  width: 150%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  backdrop-filter: blur(44px);
  border-radius: 0px 100px 100px 0px;
  transition: opacity .15s ease;
  @apply bg-green-100/15;
}

.page_link span {
  margin-right: 10px;
}

.page_link svg {
  min-height: 20px;
  min-width: 20px;
  @apply mr-3 w-5 h-5;
}

.page_link svg path:first-child {
  fill: rgba(var(--white), .5);
  transition: fill .15s ease;
}

.page_link svg path:last-child {
  stroke: rgba(var(--white), .5);
  transition: stroke .15s ease;
}

.page_link:hover, .page_link_active {
  @apply text-green-100;
}

.page_link:hover svg path:first-child {
  fill: rgb(var(--green-100));
}

.page_link:hover svg path:last-child {
  stroke: rgb(var(--green-100));
}

.page_link:hover.page_link::after {
  visibility: visible;
  opacity: 1;
}

.page_link_active svg path:first-child {
  fill: rgb(var(--green-100));
}

.page_link_active svg path:last-child {
  stroke: rgb(var(--green-100));
}

.page_link_active.page_link::after {
  visibility: visible;
  opacity: 1;
}

.circle {
  position: absolute;
  width: 320px;
  height: 320px;
  right: 12px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(17, 169, 125, 0.2) 0%, rgba(17, 169, 125, 0) 100%);
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 0;
}

.page_link .external {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-left: auto;
  transition: transform .15s ease;
}

.page_link svg.external path:first-child {
  fill: rgba(var(--white), 1);
  transition: fill .15s ease;
}

.page_link:hover svg.external path:last-child {
  stroke: rgba(var(--white), 1);
}

.page_link:hover svg.external {
  transform: translateX(4px);
}
