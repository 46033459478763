.root {
  display: flex;
  background: #165946;
  max-width: 320px;
  min-width: 160px;
  z-index: 2;
  word-break: break-word;
  width: max-content;
  box-sizing: border-box;
  padding: 8px 12px;
  border: 1px solid rgba(var(--green-100), 1);
  border-radius: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 300px;
}